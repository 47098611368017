// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import {
  BaseAPIResult,
  EntityType,
  ProfileDetailsOptionalProperties,
  WorkspaceDetailsOptionalProperties,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useUpdateProfileDetailsMutation } from '@shippypro/foundation/settings/api';

// ########################## [UTILS]
import { manageResponse } from '@shippypro/foundation/settings/utils/manage-response';

/**
 * Returns RTK Query hook with renamed data field.
 * Updates one or more profile details based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useUpdateProfileDetails = (entityId: number | string) => {
  const { t } = useTranslation();

  const [
    fetchMore,
    { data, error, isLoading, isSuccess, isError, ...results },
  ] = useUpdateProfileDetailsMutation();

  const updateProfileDetails = useCallback(
    async (
      updatedDetails:
        | ProfileDetailsOptionalProperties
        | WorkspaceDetailsOptionalProperties,
      entityType?: EntityType,
      verificationToken?: string,
    ) => {
      const apiPayload = {
        entity_type: entityType ?? EntityType.User,
        entity_id: entityId,
        ...updatedDetails,
        verification_token: verificationToken,
      };

      const result = await fetchMore(apiPayload);

      return manageResponse<BaseAPIResult>(result, t, true);
    },
    [fetchMore, t, entityId],
  );

  return useMemo(
    () => ({
      updateProfileDetails,
      data,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isSuccess,
      isError,
      ...results,
    }),
    [data, error, isError, isLoading, isSuccess, results, updateProfileDetails],
  );
};
