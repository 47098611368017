// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import {
  DeleteMFAMethodMutationPayload,
  DeleteMFAMethodMutationResult,
  EntityType,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useDeleteMFAMethodMutation } from '@shippypro/foundation/settings/api';

// ########################## [UTILS]
import { manageResponse } from '@shippypro/foundation/settings/utils/manage-response';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns the MFA Method based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useDeleteMFAMethod = (userId: number) => {
  const { t } = useTranslation();

  const [
    fetchMore,
    { data, error, isLoading, isSuccess, isError, ...results },
  ] = useDeleteMFAMethodMutation();

  const deleteMFAMethod = useCallback(
    async (
      mfaId: number,
      verificationToken: string,
      entityType?: EntityType,
    ) => {
      const apiPayload: DeleteMFAMethodMutationPayload = {
        mfa_id: mfaId,
        entity_type: entityType ?? EntityType.User,
        entity_id: userId,
        verification_token: verificationToken,
      };

      const result = await fetchMore(apiPayload);

      return manageResponse<DeleteMFAMethodMutationResult>(result, t, true);
    },
    [fetchMore, t, userId],
  );

  return useMemo(
    () => ({
      deleteMFAMethod,
      data,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isSuccess,
      isError,
      ...results,
    }),
    [deleteMFAMethod, data, error, isLoading, isSuccess, isError, results],
  );
};
