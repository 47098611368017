// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import { EntityType } from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetProfileDetailsQuery } from '@shippypro/foundation/settings/api';
import useLDFlag from '@web/hooks/useLDFlags';

// ########################## [UTILS]
import { manageResponse } from '@shippypro/foundation/settings/utils/manage-response';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns the profile details based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetProfileDetails = (
  entityId?: string | number,
  entityType?: EntityType,
) => {
  const { t } = useTranslation();

  const { settingsOnUiApp } = useLDFlag();

  const apiPayload = {
    entity_type: entityType ?? EntityType.User,
    entity_id: entityId,
  };

  const { data, isLoading, isError, error, ...rest } =
    useGetProfileDetailsQuery(apiPayload, {
      skip: !(settingsOnUiApp && entityId !== undefined),
    });

  manageResponse(data, t);

  return useMemo(
    () => ({
      profileDetails: data ?? null,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isError,
      ...rest,
    }),
    [data, error, isError, isLoading, rest],
  );
};
