// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

// ########################## [TYPES]
import {
  EntityType,
  IWorkspaceDetailsContext,
  WorkspaceDetails,
  WorkspaceDetailsOptionalProperties,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';
import { useGetProfileDetails } from '@shippypro/foundation/settings/hooks/api/useGetProfileDetails';
import useLDFlag from '@web/hooks/useLDFlags';
import { useUpdateProfileDetails } from '@shippypro/foundation/settings/hooks/api/useUpdateProfileDetails';
import { useGetRelatedEntities } from '@shippypro/foundation/settings/hooks/api/useGetRelatedEntities';
import { useKeepSessionsAlive } from '@shippypro/foundation/settings/hooks/api/useKeepSessionsAlive';

/**
 * [HOOK] useInstanceWorkspaceDetailsContext returns every data to be rendered about Workspace details,
 * together with the actions to update those details on the `Workspace details` tab
 *
 * @return {IWorkspaceDetailsContext} - The data and actions on the Workspace details tab
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const useInstanceWorkspaceDetailsContext =
  (): IWorkspaceDetailsContext => {
    const { user } = useGetUser();
    const { settingsOnUiApp } = useLDFlag();

    // Get related workspace entity
    const { relatedEntities } = useGetRelatedEntities(
      user?.id,
      EntityType.User,
    );
    const workspaceId = useMemo(() => {
      const workspaceEntity = relatedEntities.find(
        entity => entity.entity_type === EntityType.Workspace,
      );
      return workspaceEntity?.entity_id;
    }, [relatedEntities]);

    // ------ [ METHODS SETUP ] ------ //

    // BE call to get Workspace details
    const {
      profileDetails,
      isLoading: isGetWorkspaceDetailsLoading,
      isFetching: isGetWorkspaceDetailsFetching,
    } = useGetProfileDetails(workspaceId!, EntityType.Workspace);

    const workspaceDetails = profileDetails as WorkspaceDetails | null;

    // BE call to update workspace details
    const { updateProfileDetails, isLoading: isUpdateWorkspaceDetailsLoading } =
      useUpdateProfileDetails(workspaceId!);

    const setWorkspaceDetails = useCallback(
      async (
        updatedDetails: WorkspaceDetailsOptionalProperties,
        verificationToken?: string,
      ) => {
        if (settingsOnUiApp && workspaceId) {
          const result = await updateProfileDetails(
            updatedDetails,
            EntityType.Workspace,
            verificationToken,
          );
          return result;
        } else return null;
      },
      [settingsOnUiApp, updateProfileDetails, workspaceId],
    );

    // BE call to keep sessions alive
    const { refetch } = useKeepSessionsAlive();

    // Refetch every minute
    const debouncedRefetch = useRef(
      debounce(() => {
        refetch();
      }, 60000),
    );

    // Refetch every minute on mouse move
    const handleMouseMove = useCallback(() => {
      debouncedRefetch.current();
    }, [debouncedRefetch]);

    useEffect(() => {
      if (
        workspaceDetails &&
        workspaceDetails.session_expiration_minutes &&
        workspaceDetails.session_expiration_minutes > 0
      ) {
        window.addEventListener('mousemove', handleMouseMove);
        const current = debouncedRefetch.current;
        return () => {
          window.removeEventListener('mousemove', handleMouseMove);
          current.cancel(); // Clean up the debounced function
        };
      }
    }, [handleMouseMove, workspaceDetails]);

    return useMemo<IWorkspaceDetailsContext>(
      () => ({
        workspaceDetails,
        isWorkspaceDetailsLoading:
          isGetWorkspaceDetailsLoading ||
          isUpdateWorkspaceDetailsLoading ||
          isGetWorkspaceDetailsFetching,
        isGetWorkspaceDetailsLoading,
        isUpdateWorkspaceDetailsLoading,
        methods: {
          setWorkspaceDetails,
        },
      }),
      [
        workspaceDetails,
        isGetWorkspaceDetailsLoading,
        isGetWorkspaceDetailsFetching,
        isUpdateWorkspaceDetailsLoading,
        setWorkspaceDetails,
      ],
    );
  };
