import { createContext } from 'react';

import { IWorkspaceDetailsContext } from '@shippypro/foundation/settings/types';

/**
 * This context will provide all the necessary data for the `Workspace details` tabs
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const WorkspaceDetailsContext = createContext<IWorkspaceDetailsContext | null>(
  null,
);

export default WorkspaceDetailsContext;
