/* istambul ignore file */

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { TFunction } from 'i18next';

// ########################## [DESIGN SYSTEM]
import {
  showErrorToastr,
  showSecondaryToastr,
} from '@shippypro/design-system-web/functions';

// ########################## [TYPES]
import { BaseAPIResult } from '@shippypro/foundation/settings/types';

export const manageResponse = <T extends BaseAPIResult>(
  result: any,
  t: TFunction,
  showConfirmToastr = false,
): T => {
  const transCommon = translations.common;

  if (result && 'data' in result) {
    const data = result.data;
    if (data && data.success && showConfirmToastr)
      showSecondaryToastr(t(transCommon.form.settingsSaved));
    return data;
  } else if (result && 'error' in result) {
    const error = result.error;
    if (error && 'data' in error) {
      const data = error.data as T;
      if (data && !data.success && data.message) {
        showErrorToastr(t(transCommon.errorFound), data.message);
      }
    }
  }
  return result;
};
