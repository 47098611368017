import { Dispatch, SetStateAction, createContext } from 'react';

import { TabConfiguration } from '@shippypro/design-system-web/types';

import { Order } from '@web/types/order';
import {
  OrdersNumber,
  OrdersPrevalidationSetting,
  RowDensity,
  UserConfiguredTabsResult,
} from '@web/features/ship/types';
import {
  PaginatedData,
  Pagination,
  RemoteTableData,
  Sorting,
} from '@web/types/tables';
import { DeliverView } from '@web/types/user/preferences/deliver-views';
import { Filtering } from '@web/features/ship/types/filtering';

import { TabClickHandlerFn } from '@web/hooks/layout/useParseTabConfigWithHashRouter';

export interface IUserConfiguredTabsContextProps {
  tabs: ShipTab[];
  apiTabs: UserConfiguredTabsResult | undefined;
  methods: {
    setAreAllTabsFetching: Dispatch<SetStateAction<boolean>>;
    loadTabsWithConfiguration: (tabs: TabConfiguration[]) => void;
    getTabByURL: (url: string) => ShipTab | undefined;
    getAPITabByUrl: (url: string) => DeliverView | undefined;

    setCurrentHash: Dispatch<SetStateAction<string>>;
    setActiveTabFn: Dispatch<SetStateAction<TabClickHandlerFn | undefined>>;
    setActiveTab?: TabClickHandlerFn | undefined;

    setTabLoading: (isLoading: boolean, url?: string) => void;
    setTabOrders: (orders: PaginatedData<Order>, url?: string) => void;
    setTabSorting: (sorting: Sorting | null, url?: string) => void;
    setTabPagination: (pagination: Pagination | null, url?: string) => void;
    setTabFiltering: (
      filtering: Filtering,
      isFiltered: boolean,
      url?: string,
    ) => void;
    setTabRowDensity: (rowDensity: RowDensity | null, url?: string) => void;
    setTabOrdersNumber: (
      ordersNumber: OrdersNumber | null,
      url?: string,
    ) => void;
    setTabOrdersPrevalidation: (
      ordersPrevalidation: OrdersPrevalidationSetting | null,
      url?: string,
    ) => void;
    setOrdersSelection: (orders: Order[], url?: string) => void;
    addOrdersToSelection: (orders: Order[], url?: string) => void;
    removeOrdersFromSelection: (ids: number[], url?: string) => void;
  };
  utils: {
    areAPITabsReloading: boolean;
    areAllTabsFetching: boolean;

    currentTabHash: string;
    currentTab: ShipTab | undefined;
    currentAPITab: DeliverView | undefined;
  };
}

export type ShipTab = RemoteTableData<Order, Filtering> & {
  url: string;
  title: string;

  isLoading: boolean;
  isFiltered: boolean;
  isVisible: boolean;
  isSelectionActive: boolean;

  rowCount: number;
  rowDensity: RowDensity | null;
  ordersNumber: OrdersNumber | null;
  ordersPrevalidation: OrdersPrevalidationSetting | null;
  selectedOrderIDs: number[];
  selectedOrders: Order[];
};

/**
 * This context will be the home of every configuration that the user can set for the tabs.
 * It will also register changes on the contents of said tabs (selected orders, number of fetched rows, local filters, etc.)
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const UserConfiguredTabsContext =
  createContext<IUserConfiguredTabsContextProps | null>(null);

export default UserConfiguredTabsContext;
