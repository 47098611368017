// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { showErrorToastr } from '@shippypro/design-system-web/functions';

// ########################## [STATE MANAGEMENT]
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

// ########################## [HOOKS]
import { useKeepSessionsAliveQuery } from '@shippypro/foundation/settings/api';

/**
 * Returns RTK Query hook with renamed data field.
 * Tells the backend to keep the sessions alive.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useKeepSessionsAlive = (options?: CustomRTKQueryOptions) => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const { data, error, isFetching, ...rest } = useKeepSessionsAliveQuery(
    {},
    options,
  );

  if (data && !data?.success) {
    showErrorToastr(t(transCommon.errorFound), data.message);
  }

  return useMemo(
    () => ({
      unparsedError: error,
      isFetching,
      ...rest,
    }),
    [error, isFetching, rest],
  );
};
