import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { UserPreferencesDomain } from '@web/types/user/preferences';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';
import { getMicroserviceUrl } from '@shippypro/api-client/utils';

import { MicroserviceNames } from '@shippypro/api-client/types';
import { EntityType } from '@shippypro/foundation/settings/types';

type PROFILESENGINE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'POST'
>;

/**
 * This method scaffolds the query object for the "Profiles Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {PROFILESENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
function getProfilesEngineMicroserviceQuery<ArgT>(
  url: string,
  method: PROFILESENGINE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.PROFILES_ENGINE)}/v1/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Profiles Engine" microservice API request.
 * It specifically targets the "preferences" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {UserPreferencesDomain} domain The domain set of the user preferences object.
 * @param {PROFILESENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getProfilesEnginePreferencesMicroserviceQuery<
  ArgT extends { user_id: number; [key: string]: unknown | undefined },
>(
  domain: UserPreferencesDomain,
  method: PROFILESENGINE_ALLOWED_METHODS,
  args: ArgT,
): FetchArgs {
  return getProfilesEngineMicroserviceQuery<ArgT>(
    `${args.user_id}/preferences/${domain}`,
    method,
    args,
  );
}

/** FIXME: fix documentation
 * This method scaffolds the query object for the "Profiles Engine" microservice API request.
 * It specifically targets the "settings" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {PROFILESENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {EntityType} entity_type The entity type (user, wholesaler).
 * @param {string | number} entity_id The entity id.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getProfilesEngineV2MicroserviceQuery<
  ArgT extends {
    [key: string]: unknown | undefined;
  },
>(
  url: string,
  method: PROFILESENGINE_ALLOWED_METHODS,
  entity_id: string | number,
  entity_type: EntityType,
  args: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.PROFILES_ENGINE)}/v2/${entity_type}/${entity_id}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Profiles Engine" microservice API request.
 * It specifically targets the "settings" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {PROFILESENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {EntityType} entity_type The entity type (user, wholesaler).
 * @param {string | number} entity_id The entity id.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getProfilesEngineSettingsMicroserviceQuery<
  ArgT extends {
    [key: string]: unknown | undefined;
  },
>(
  url: string,
  method: PROFILESENGINE_ALLOWED_METHODS,
  entity_id: string | number,
  entity_type: EntityType,
  args: ArgT,
  verification_token?: string,
): FetchArgs {
  const builtArgs = getProfilesEngineV2MicroserviceQuery(
    `settings/${url}`,
    method,
    entity_id,
    entity_type,
    args,
  );
  if (verification_token)
    builtArgs.headers = {
      'X-ShippyPro-Verification-Token': verification_token,
    };
  return builtArgs;
}
