// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import {
  EntityType,
  UpdatePasswordMutationPayload,
  UpdatePasswordMutationyResult,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useUpdatePasswordMutation } from '@shippypro/foundation/settings/api';

// ########################## [UTILS]
import { manageResponse } from '@shippypro/foundation/settings/utils/manage-response';

/**
 * Returns RTK Query hook with renamed data field.
 * Updates one or more profile details based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useUpdatePassword = (userId: number) => {
  const { t } = useTranslation();

  const [
    fetchMore,
    { data, error, isLoading, isSuccess, isError, ...results },
  ] = useUpdatePasswordMutation();

  const updatePassword = useCallback(
    async (
      newPassword: string,
      oldPassword: string,
      verificationToken?: string,
      entityType?: EntityType,
    ) => {
      const apiPayload: UpdatePasswordMutationPayload = {
        entity_type: entityType ?? EntityType.User,
        entity_id: userId,
        new_password: newPassword,
        old_password: oldPassword,
        verification_token: verificationToken,
      };

      const result = await fetchMore(apiPayload);

      return manageResponse<UpdatePasswordMutationyResult>(result, t, true);
    },
    [fetchMore, t, userId],
  );

  return useMemo(
    () => ({
      updatePassword,
      data,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isSuccess,
      isError,
      ...results,
    }),
    [data, error, isError, isLoading, isSuccess, results, updatePassword],
  );
};
